import React, { useState, useEffect, useRef, RefObject, MutableRefObject } from 'react';
import { Chevron, PageFolded } from '@solent-university/solent-icons';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import { LinkProps } from 'Elements/Link';
import { IconButtonProps } from 'Elements/Button/Icon';

import Grid, { Col } from 'Components/Grid';

import {
    Container,
    ToggleMenuContainer,
    ToggleMenuButton,
    Open,
    Overlay,
    StyledIconButton,
    Close,
    StyledList,
    StyledListItem,
    LinkIconWrapper,
    LinkIcon,
    NavItemLink,
    NavItemButton,
    BackButton,
    BackButtonChevron
} from './SectionNavigation.styled';

export interface NavItem extends LinkProps {
    id: string;
    children?: NavItem[];
}

export interface Props {
    sectionParentButton?: IconButtonProps;
    navItems: NavItem[];
    initialActiveItem: string;
}

interface MenuColumnState {
    activeId: string | undefined;
    items: NavItem[] | undefined;
}

interface RenderColumnProps {
    items: NavItem[];
    handleOnClick?: (item) => void;
    columnRef?: RefObject<HTMLUListElement>;
    prevColumnRef?: RefObject<HTMLUListElement>;
}

const SectionNavigation: React.FC<Props> = props => {
    const { sectionParentButton, navItems, initialActiveItem } = props;
    // const isMinHeight = useBreakpoint(320, { type: 'height' });

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [columnOneActiveId, setcolumnOneActiveId] = useState<string | undefined>(undefined);
    const [columnTwo, setColumnTwo] = useState<MenuColumnState>({
        activeId: undefined,
        items: undefined
    });
    const [columnThree, setColumnThree] = useState<MenuColumnState>({
        activeId: undefined,
        items: undefined
    });
    const [activeMobileColumn, setActiveMobileColumn] = useState<number>(1);
    const [isSticky, setIsSticky] = useState(false);

    const columnOneRef = useRef() as RefObject<HTMLUListElement>;
    const columnTwoRef = useRef() as RefObject<HTMLUListElement>;
    const columnThreeRef = useRef() as RefObject<HTMLUListElement>;
    const stickyTriggerRef = useRef() as MutableRefObject<HTMLDivElement>;

    const { breakpoints } = useTheme();
    const isTablet = !useBreakpoint(breakpoints.large.value);

    /**
     * Populates the columns states on first render
     */
    useEffect(() => {
        navItems.map(item => {
            item.children &&
                item.children.map(itemColTwo => {
                    if (itemColTwo.id === initialActiveItem) {
                        setcolumnOneActiveId(item.id);
                        setColumnTwo({
                            activeId: undefined,
                            items: item.children
                        });
                        setActiveMobileColumn(2);
                    } else {
                        itemColTwo.children &&
                            itemColTwo.children.map(itemColThree => {
                                if (itemColThree.id === initialActiveItem) {
                                    setcolumnOneActiveId(item.id);
                                    setColumnTwo({
                                        activeId: itemColTwo.id,
                                        items: item.children
                                    });
                                    setColumnThree({
                                        activeId: undefined,
                                        items: itemColTwo.children
                                    });
                                    setActiveMobileColumn(3);
                                }
                            });
                    }
                });
        });
    }, [initialActiveItem]);

    /**
     * Render a specific column and it's submenu
     * @param  {RenderColumnProps} props Interface specific to these props
     */
    const renderColumn = (props: RenderColumnProps) => {
        const { items, handleOnClick, columnRef, prevColumnRef } = props;

        return (
            <Col l={4}>
                <StyledList
                    key={`${items[0].id}-list`}
                    role="menu"
                    ref={columnRef}
                    onKeyDown={e =>
                        !isTablet && columnRef && handleKeyPress(e, columnRef, prevColumnRef)
                    }
                >
                    {items.map(item => (
                        <StyledListItem
                            key={item.id}
                            $active={item.id === initialActiveItem ? true : false}
                            $open={
                                !isTablet &&
                                (item.id === columnOneActiveId || item.id === columnTwo.activeId)
                            }
                            role="presentation"
                        >
                            {item.children && handleOnClick ? (
                                <NavItemButton
                                    aria-label={`Open ${item.label} submenu`}
                                    onClick={() => handleOnClick(item)}
                                    role="menuitem"
                                >
                                    <LinkIconWrapper>
                                        <LinkIcon as={Chevron} />
                                    </LinkIconWrapper>
                                    {item.label}
                                </NavItemButton>
                            ) : (
                                <NavItemLink
                                    {...item}
                                    label={undefined}
                                    variant="text"
                                    role="menuitem"
                                    onClick={() => {
                                        handleOnClick && handleOnClick(item);
                                        setIsOpen(false);
                                    }}
                                >
                                    <LinkIconWrapper>
                                        <LinkIcon as={PageFolded} $isPage />
                                    </LinkIconWrapper>
                                    {item.label}
                                </NavItemLink>
                            )}
                        </StyledListItem>
                    ))}
                </StyledList>
            </Col>
        );
    };

    /**
     * Renders the mobile specific columns & submenus
     */
    const renderMobileSwitch = () => {
        switch (activeMobileColumn) {
            case 2:
                return (
                    columnTwo.items &&
                    columnTwo.items.length > 0 &&
                    renderColumn({
                        items: columnTwo.items,
                        handleOnClick: item => {
                            setColumnTwo({
                                ...columnTwo,
                                activeId: item.id
                            });
                            setColumnThree({
                                activeId: undefined,
                                items: item.children
                            });
                            setActiveMobileColumn(3);
                        }
                    })
                );
            case 3:
                return (
                    columnThree.items &&
                    columnThree.items.length > 0 &&
                    renderColumn({ items: columnThree.items })
                );
            default:
                return renderColumn({
                    items: navItems,
                    handleOnClick: item => {
                        setcolumnOneActiveId(item.id);
                        setColumnTwo({
                            activeId: undefined,
                            items: item.children
                        });
                        setColumnThree({
                            activeId: undefined,
                            items: undefined
                        });
                        setActiveMobileColumn(2);
                    }
                });
        }
    };

    /**
     * Handle the keypressing for accessibility
     * @param  {React.KeyboardEvent<HTMLUListElement>} e Key press event
     * @param  {RefObject<HTMLUListElement>} columnRef Ref for the current column
     * @param  {RefObject<HTMLUListElement>} prevColumnRef Ref for the previous column
     */
    const handleKeyPress = (
        e: React.KeyboardEvent<HTMLUListElement>,
        columnRef: RefObject<HTMLUListElement>,
        prevColumnRef?: RefObject<HTMLUListElement>
    ) => {
        switch (e.key) {
            case 'Escape':
                prevColumnRef ? changeFocus(prevColumnRef) : setIsOpen(false);
                break;
            case 'Tab': {
                if (columnRef.current) {
                    const focusableModalElements = columnRef.current.querySelectorAll(
                        'a[href], button'
                    ) as NodeListOf<HTMLElement>;

                    const firstElement = focusableModalElements[0];
                    const lastElement = focusableModalElements[focusableModalElements.length - 1];

                    if (!e.shiftKey && document.activeElement === lastElement) {
                        e.preventDefault();
                        firstElement.focus();
                    }

                    if (e.shiftKey && document.activeElement === firstElement) {
                        e.preventDefault();
                        lastElement.focus();
                    }
                }
                break;
            }
            default:
                break;
        }
    };

    /**
     * Change focus to the first element of the submenu
     * @param  {RefObject<HTMLUListElement>} columnRef The column reference of the submenu
     */
    const changeFocus = (columnRef: RefObject<HTMLUListElement>) => {
        if (columnRef.current) {
            const focusableModalElements = columnRef.current.querySelectorAll(
                'a[href], button'
            ) as NodeListOf<HTMLElement>;
            focusableModalElements[0].focus();
        }
    };

    /**
     * Change focus to the second column submenu
     */
    useEffect(() => {
        !isTablet && isOpen && columnTwo.items && changeFocus(columnTwoRef);
    }, [columnTwo.items]);

    /**
     * Change focus to the third column submenu
     */
    useEffect(() => {
        !isTablet && isOpen && columnThree.items && changeFocus(columnThreeRef);
    }, [columnThree.items]);

    /**
     * Listen to the scroll and check when sticky is applied
     */
    useEffect(() => {
        function handleScroll() {
            const el = stickyTriggerRef.current;

            const viewportOffset = el.getBoundingClientRect();

            setIsSticky(viewportOffset.top <= 100);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Container ref={stickyTriggerRef}>
            <Grid>
                <Col>
                    <ToggleMenuContainer $isSticky={isSticky}>
                        <ToggleMenuButton
                            aria-label="Open section menu"
                            onClick={() => setIsOpen(true)}
                        >
                            Related content <Open />
                        </ToggleMenuButton>
                    </ToggleMenuContainer>
                </Col>
            </Grid>
            <Overlay $isOpen={isOpen} aria-hidden={!isOpen}>
                <Grid>
                    <Col>
                        <ToggleMenuContainer $isSticky={isSticky}>
                            <ToggleMenuButton
                                aria-label="Close section menu"
                                onClick={() => setIsOpen(false)}
                            >
                                <Close />
                            </ToggleMenuButton>
                        </ToggleMenuContainer>
                        {sectionParentButton && (
                            <StyledIconButton
                                {...sectionParentButton}
                                onClick={() => {
                                    setIsOpen(false);
                                    setcolumnOneActiveId(undefined);
                                    setColumnTwo({
                                        activeId: undefined,
                                        items: undefined
                                    });
                                    setColumnThree({
                                        activeId: undefined,
                                        items: undefined
                                    });
                                    setActiveMobileColumn(1);
                                }}
                                variant="secondaryTintOne"
                            />
                        )}
                        {activeMobileColumn > 1 && isTablet && (
                            <BackButton
                                aria-label="Go back to previous submenu"
                                onClick={() => setActiveMobileColumn(activeMobileColumn - 1)}
                            >
                                {[...Array(activeMobileColumn - 1)].map((_, index) => (
                                    <BackButtonChevron key={index} />
                                ))}
                                Back
                            </BackButton>
                        )}
                        {!isTablet ? (
                            <nav id="section-navigation" aria-label="Section navigation">
                                <Grid>
                                    {renderColumn({
                                        items: navItems,
                                        handleOnClick: item => {
                                            setcolumnOneActiveId(item.id);
                                            setColumnTwo({
                                                activeId: undefined,
                                                items: item.children
                                            });
                                            setColumnThree({
                                                activeId: undefined,
                                                items: undefined
                                            });
                                            changeFocus(columnTwoRef);
                                        },
                                        columnRef: columnOneRef
                                    })}
                                    {columnTwo.items &&
                                        columnTwo.items.length > 0 &&
                                        renderColumn({
                                            items: columnTwo.items,
                                            handleOnClick: item => {
                                                setColumnTwo({
                                                    ...columnTwo,
                                                    activeId: item.id
                                                });
                                                setColumnThree({
                                                    activeId: undefined,
                                                    items: item.children
                                                });
                                                changeFocus(columnThreeRef);
                                            },
                                            columnRef: columnTwoRef,
                                            prevColumnRef: columnOneRef
                                        })}
                                    {columnThree.items &&
                                        columnThree.items.length > 0 &&
                                        renderColumn({
                                            items: columnThree.items,
                                            columnRef: columnThreeRef,
                                            prevColumnRef: columnTwoRef
                                        })}
                                </Grid>
                            </nav>
                        ) : (
                            <nav id="section-navigation" aria-label="Section navigation">
                                <Grid>{renderMobileSwitch()}</Grid>
                            </nav>
                        )}
                    </Col>
                </Grid>
            </Overlay>
        </Container>
    );
};

export default SectionNavigation;
