import React from 'react';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import { DescriptionWrapper, ImageWrapper } from './ImageAndText.styled';
import Grid, { Col } from 'Components/Grid';
import ImageCaption, { ImageCaptionProps } from 'Components/ImageCaption';

export interface Props {
    description: React.ReactNode;
    imageCaption: ImageCaptionProps;
    imageLeftOfDescription: boolean;
    imageColumnWidth: number;
}

const ImageAndText: React.FC<Props> = props => {
    const { description, imageCaption, imageLeftOfDescription, imageColumnWidth } = props;

    const { breakpoints } = useTheme();
    const isDesktop = useBreakpoint(breakpoints.large.value);

    return (
        <Grid rowGap="1rem">
            <Col
                l={12 - imageColumnWidth}
                order={isDesktop ? (imageLeftOfDescription ? 1 : 'initial') : 0}
            >
                <DescriptionWrapper>{description}</DescriptionWrapper>
            </Col>
            <Col l={imageColumnWidth}>
                <ImageWrapper>
                    <ImageCaption {...imageCaption} />
                </ImageWrapper>
            </Col>
        </Grid>
    );
};

export default ImageAndText;
