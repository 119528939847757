import type { PageEntry, Node } from 'contensis-delivery-api';
import { LightbulbCog } from '@solent-university/solent-icons';
import cmsParser from 'Common/util/cmsParser';

import { composerFlexibleContentPage } from 'Common/util/cmsComposer';

import type { Props } from './ContentComposer';

export type PageData = {
    entry: PageEntry;
    ancestors: Node[];
    sectionParent: Node;
};

const datamap = (data: PageData): Props => {
    const { entry, ancestors, sectionParent } = data;

    const {
        title,
        sys,
        heroBannerWaveDefaultCondensed,
        inPageNavHeading,
        composerContent,
        unibuddyMetadata
    } = entry;

    const createSectionNav = (children: Node[], parent?: Node) => {
        const navSegment = children.map(child => ({
            id:
                child.children.length > 0
                    ? `${child.entry ? child.entry.sys.id : child.id}-parent`
                    : child.entry
                    ? child.entry.sys.id
                    : child.displayName,
            label: child.entry ? child.entry.title : child.displayName,
            ariaLabel: `Click to visit ${child.entry ? child.entry.title : child.displayName}`,
            href: child.path,
            children:
                child.children.length > 0 ? createSectionNav(child.children, child) : undefined
        }));

        parent &&
            parent.entry &&
            navSegment.unshift({
                id: parent.entry.sys.id,
                label: parent.entry.title,
                ariaLabel: `Click to visit ${parent.entry.title}`,
                href: parent.path
            });

        return navSegment;
    };

    const sectionNav = createSectionNav(sectionParent.children);

    return {
        breadcrumbs: cmsParser.breadcrumbs([...ancestors, { entry: { title: title }, id: sys.id }]),
        heroBanner: {
            ...cmsParser.heroBannerWave(heroBannerWaveDefaultCondensed),
            oneColumn: true,
            condensed: true
        },
        sectionNavigation: {
            sectionParentButton: sectionParent.entry
                ? {
                      icon: LightbulbCog,
                      ariaLabel: `Visit ${sectionParent.entry.title} home`,
                      label: `Visit ${sectionParent.entry.title} home`,
                      href: sectionParent.entry.sys.uri
                  }
                : undefined,
            navItems: sectionNav,
            initialActiveItem: entry.sys.id
        },
        inPageNavigation: {
            id: 'in-page-navigation',
            heading: inPageNavHeading,
            links: composerContent
                .filter(item => item.type === 'anchorLink')
                .map((item, index) => ({
                    id: index,
                    label: item.value.label,
                    ariaLabel: item.value.ariaLabel,
                    href: `#${item.value.anchor}`
                }))
        },
        content: composerFlexibleContentPage(composerContent),
        uniBuddyPopcard: {
            hide: unibuddyMetadata?.hideUnibuddy,
            filterValue: unibuddyMetadata?.courseLevelFilter
        }
    };
};

export default datamap;
