import React from 'react';

import UniBuddy, { UniBuddyPopCardProps } from 'Common/util/uniBuddy';

import Main from 'Elements/Main';
import { H2, H3 } from 'Elements/Typography';
import FlexBox from 'Elements/FlexBox';

import Grid, { Col } from 'Components/Grid';
import Breadcrumbs, { BreadcrumbsProps } from 'Components/Breadcrumbs';
import ImageCaption from 'Components/ImageCaption';
import QuoteBlock from 'Components/QuoteBlock';
import Video from 'Components/Video';
import AttentionBox from 'Components/AttentionBox';
import Accordion from 'Components/Accordion';
import List from 'Components/List';
import Table from 'Components/Table';
import IframeGecko from 'Components/IframeGecko';
import Unibuddy from 'Components/Unibuddy';
import UnibuddyCarousel from 'Components/Unibuddy/Carousel';
import ImageAndText from 'Components/TextBlock/ImageAndText';
import ContactInfo from 'Components/SupportBlock/ContactInfo';
import Profile from 'Components/Card/Profile';

import MakeWavesHeroBanner, { MakeWavesHeroBannerProps } from 'Modules/HeroBanner/MakeWaves';
import SectionNavigation, { SectionNavigationProps } from 'Modules/SectionNavigation';
import InPageNavigation, { InPageNavigationProps } from 'Modules/InPageNavigation';
import LinkCards from 'Modules/Cards/Link';
import MiniCards from 'Modules/Cards/Mini';
import BrandBanner from 'Modules/Banner/Brand';
import TefAward from 'Modules/PromoPoint/TefAward';

import type { ContentComposerProps } from 'Common/util/cmsComposer/FlexibleContentPage';

import { ContentItem, AnchorContainer, Anchor } from '../Content.styled';

export interface Props {
    breadcrumbs: BreadcrumbsProps;
    heroBanner: MakeWavesHeroBannerProps;
    sectionNavigation: SectionNavigationProps;
    inPageNavigation: InPageNavigationProps;
    content: ContentComposerProps[];
    uniBuddyPopcard: { hide: boolean } & UniBuddyPopCardProps;
}

/**
 * Add names of composer blocks here where the line breaks at the bottom of the page are not required.
 * These blocks would typically be the ones that have their own background colour
 **/
const marginBottomRuleset = ['brandBanner', 'geckoForm', 'unibuddyMain', 'unibuddyCarousel'];

const Content: React.FC<Props> = props => {
    const {
        breadcrumbs,
        heroBanner,
        sectionNavigation,
        inPageNavigation,
        content,
        uniBuddyPopcard
    } = props;

    return (
        <>
            <Breadcrumbs {...breadcrumbs} />
            <MakeWavesHeroBanner {...heroBanner} />
            <Main>
                <SectionNavigation {...sectionNavigation} />
                {inPageNavigation.links.length > 0 && (
                    <Grid>
                        <Col l={[3, 11]}>
                            <InPageNavigation {...inPageNavigation} />
                        </Col>
                    </Grid>
                )}
                {content.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.text && <ComposerItemWrapper>{item.text}</ComposerItemWrapper>}
                        {item.image && (
                            <ComposerItemWrapper
                                columnStart={7 - item.image.columnWidth / 2}
                                columnEnd={7 + item.image.columnWidth / 2}
                            >
                                <ImageCaption {...item.image.imageCaption} />
                            </ComposerItemWrapper>
                        )}
                        {item.quote && (
                            <ComposerItemWrapper>
                                <QuoteBlock {...item.quote} />
                            </ComposerItemWrapper>
                        )}
                        {item.textImageTwoColumns && (
                            <ComposerItemWrapper>
                                <ImageAndText {...item.textImageTwoColumns} />
                            </ComposerItemWrapper>
                        )}
                        {item.video && (
                            <ComposerItemWrapper>
                                {item.video.heading && <h2>{item.video.heading}</h2>}
                                <Video {...item.video.embed} />
                            </ComposerItemWrapper>
                        )}
                        {item.attentionBox && (
                            <ComposerItemWrapper columnStart={4} columnEnd={10}>
                                <AttentionBox {...item.attentionBox} />
                            </ComposerItemWrapper>
                        )}
                        {item.accordion && (
                            <ComposerItemWrapper>
                                {item.accordion.map(({ children, ...rest }, index) => (
                                    <Accordion {...rest} defaultOpen={index === 0} key={rest.id}>
                                        {children}
                                    </Accordion>
                                ))}
                            </ComposerItemWrapper>
                        )}
                        {item.listTwoColumns && (
                            <ComposerItemWrapper>
                                <Grid>
                                    <Col l={6}>
                                        {item.listTwoColumns.listOneHeading && (
                                            <H2 spacing={{ base: { margin: 0 } }}>
                                                {item.listTwoColumns.listOneHeading}
                                            </H2>
                                        )}
                                        <List {...item.listTwoColumns.listOne} />
                                    </Col>
                                    <Col l={6}>
                                        {item.listTwoColumns.listTwoHeading && (
                                            <H2 spacing={{ base: { margin: 0 } }}>
                                                {item.listTwoColumns.listTwoHeading}
                                            </H2>
                                        )}
                                        <List {...item.listTwoColumns.listTwo} />
                                    </Col>
                                </Grid>
                            </ComposerItemWrapper>
                        )}
                        {item.table && (
                            <ComposerItemWrapper columnStart={1} columnEnd={13}>
                                <Table {...item.table} />
                            </ComposerItemWrapper>
                        )}
                        {item.promoCards && (
                            <ComposerItemWrapper columnStart={1} columnEnd={13}>
                                <LinkCards {...item.promoCards} />
                            </ComposerItemWrapper>
                        )}
                        {item.meetTheTeam && (
                            <ComposerItemWrapper columnStart={1} columnEnd={13}>
                                {item.meetTheTeam.heading && (
                                    <Grid>
                                        <Col>
                                            <H2>{item.meetTheTeam.heading}</H2>
                                        </Col>
                                    </Grid>
                                )}
                                <Grid>
                                    <Col>
                                        <MiniCards {...item.meetTheTeam.cards} />
                                    </Col>
                                </Grid>
                            </ComposerItemWrapper>
                        )}
                        {item.brandBanner && (
                            <ContentItem>
                                <BrandBanner {...item.brandBanner} />
                            </ContentItem>
                        )}
                        {item.tefAward && (
                            <ContentItem>
                                <TefAward {...item.tefAward} />
                            </ContentItem>
                        )}
                        {item.geckoForm && (
                            <ContentItem>
                                <IframeGecko {...item.geckoForm} />
                            </ContentItem>
                        )}
                        {item.anchor && (
                            <Col l={[3, 11]}>
                                <AnchorContainer>
                                    <Anchor id={item.anchor} />
                                </AnchorContainer>
                            </Col>
                        )}
                        {item.unibuddyMain && (
                            <Unibuddy {...item.unibuddyMain} columnStart={3} columnEnd={11} />
                        )}
                        {item.unibuddyCarousel && (
                            <UnibuddyCarousel
                                {...item.unibuddyCarousel}
                                columnStart={3}
                                columnEnd={11}
                            />
                        )}
                        {item.flexibleAcademicStaffProfile && (
                            <Grid>
                                <Col m={[0, 7]} l={[4, 10]}>
                                    <ContentItem>
                                        {item.flexibleAcademicStaffProfile.heading && (
                                            <H3
                                                size={{ base: '1.125rem', large: '1.5rem' }}
                                                spacing={{
                                                    base: { margin: '0 0 1rem 0' },
                                                    medium: { margin: '0 0 2rem 0' }
                                                }}
                                            >
                                                {item.flexibleAcademicStaffProfile.heading}
                                            </H3>
                                        )}
                                        <Profile {...item.flexibleAcademicStaffProfile} border />
                                    </ContentItem>
                                </Col>
                            </Grid>
                        )}
                        {item.contactInformation && (
                            <Grid>
                                <Col l={[3, 11]}>
                                    <ContentItem>
                                        {item.contactInformation.heading && (
                                            <h2>{item.contactInformation.heading}</h2>
                                        )}
                                        <FlexBox
                                            cols={{ base: 1, medium: 2 }}
                                            gap="1.5rem"
                                            wrap="wrap"
                                        >
                                            {item.contactInformation.contacts.map(
                                                (contact, index) => (
                                                    <ContactInfo key={index} {...contact} />
                                                )
                                            )}
                                        </FlexBox>
                                    </ContentItem>
                                </Col>
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
                {content.length > 0 &&
                    !marginBottomRuleset.includes(Object.keys(content[content.length - 1])[0]) && (
                        <>
                            <br />
                            <br />
                        </>
                    )}
                {!uniBuddyPopcard.hide &&
                    UniBuddy({
                        filterValue: uniBuddyPopcard.filterValue
                            ? uniBuddyPopcard.filterValue
                            : 'All'
                    })}
            </Main>
        </>
    );
};

const ComposerItemWrapper: React.FC<{
    columnStart?: number;
    columnEnd?: number;
}> = props => {
    const { children, columnStart = 3, columnEnd = 11 } = props;

    return (
        <Grid>
            <Col l={[columnStart, columnEnd]}>
                <ContentItem>{children}</ContentItem>
            </Col>
        </Grid>
    );
};

export default Content;
