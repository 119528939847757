import React from 'react';
import type { NextPageWithLayout, GetServerSideProps } from 'next';
import contensis from 'Common/api/contensis';
import cmsParser from 'Common/util/cmsParser';
import { excludeMetadata } from 'Common/util/contensis/api';
import setCacheHeaders from 'Common/func/setCacheHeaders';

import MainLayout from 'Layout/Main';
import Metadata from 'Components/Metadata';

import Landing, {
    datamap as datamapLanding,
    PageData as PageDataLanding
} from 'Templates/Flexible/Landing';

import ContentComposer, {
    datamap as datamapContentComposer,
    PageData as PageDataContentComposer
} from 'Templates/Flexible/Content/Composer';
import ContentCanvas, {
    datamap as datamapContentCanvas,
    PageData as PageDataContentCanvas
} from 'Templates/Flexible/Content/Canvas';

import { Op, OrderBy } from 'contensis-delivery-api';

type Props = PageDataLanding & PageDataContentComposer & PageDataContentCanvas;

const Generic: NextPageWithLayout<Props> = ({ entry, ancestors, courses, sectionParent }) => {
    const renderTemplate = (contentTypeId: string) => {
        switch (contentTypeId) {
            case 'flexibleLandingPage':
                return (
                    <Landing
                        {...datamapLanding({
                            entry,
                            ancestors,
                            courses
                        })}
                    />
                );
            case 'flexiContentPage':
                return (
                    <ContentComposer
                        {...datamapContentComposer({
                            entry,
                            ancestors,
                            sectionParent
                        })}
                    />
                );
            case 'flexiContentPageCanvas':
                return (
                    <ContentCanvas
                        {...datamapContentCanvas({
                            entry,
                            ancestors,
                            sectionParent
                        })}
                    />
                );
            default:
        }
    };

    return (
        <>
            <Metadata {...cmsParser.metadata(entry)} />
            {renderTemplate(entry.sys.contentTypeId)}
        </>
    );
};

Generic.getLayout = (page, props) => {
    return <MainLayout {...props}>{page}</MainLayout>;
};

export const getServerSideProps: GetServerSideProps<any> = async ({ params, preview, res }) => {
    const { fetch, surrogateKeys } = contensis({ preview, fetchSurrogateKeys: true });

    const { generic: path } = params as { generic: string[] };

    const { data, error } = await fetch(async (client, search) => {
        // TODO - Look into further data optimsation
        const resPage = await client.nodes.get({
            path: `/${path.join('/')}`,
            entryLinkDepth: 3,
            entryFields: ['*', '-taughtCourses', ...excludeMetadata]
        });

        if (!resPage.entry) {
            return {
                error: true
            };
        }

        const entry = resPage.entry;

        const ancestors = await client.nodes.getAncestors({
            id: resPage.id,
            entryFields: ['title']
        });

        switch (entry.sys.contentTypeId) {
            case 'flexibleLandingPage': {
                const searchComponents = entry.pageElements.filter(
                    item => item.type === 'textBlockWithCourseSearch'
                );

                let resCourses;
                if (searchComponents.length > 0) {
                    const degreeTypes: string[] = [];
                    searchComponents.map(component =>
                        degreeTypes.push(...component.value.courseSearchDegreeTypes)
                    );

                    const degreeTypesNoDuplicates = [...new Set(degreeTypes)];

                    const contentTypeQueries: any[] = [];

                    degreeTypesNoDuplicates.map(degreeType =>
                        contentTypeQueries.push(Op.equalTo('sys.contentTypeId', degreeType))
                    );

                    const coursesQuery = Op.or(...contentTypeQueries);

                    resCourses = await search(coursesQuery, {
                        orderBy: OrderBy.asc('name'),
                        pageSize: 400,
                        fields: ['name', 'sys', 'id', 'degreeType']
                    });
                }

                return {
                    entry,
                    ancestors,
                    courses: resCourses ? resCourses.items.filter(item => item.sys.uri) : []
                };
            }
            case 'flexiContentPage':
            case 'flexiContentPageCanvas': {
                const sectionParentID = ancestors.length > 1 ? ancestors[1].id : resPage.id;

                const sectionParent = await client.nodes.get({
                    id: sectionParentID,
                    depth: 3,
                    entryFields: [
                        'heroBannerWaveDefault.heading',
                        'title',
                        'sys',
                        ...excludeMetadata
                    ]
                });

                return {
                    entry,
                    ancestors,
                    sectionParent
                };
            }
            default:
                return {
                    error: true
                };
        }
    });

    if (error) {
        return {
            notFound: true
        };
    } else {
        setCacheHeaders(res, surrogateKeys);

        return {
            props: data
        };
    }
};

export default Generic;
