import styled, { css, keyframes } from 'styled-components';
import { Plus, Cross, Chevron } from '@solent-university/solent-icons';

import IconButton from 'Elements/Button/Icon';
import { Icon } from 'Elements/Button/Icon/Icon.styled';
import Link from 'Elements/Link';

const fadeSlideIn = keyframes`
    from {
        right: -3rem;
        opacity: 0;
    }

    to {
        right: 0;
        opacity: 1;
    }
`;

export const Container = styled.div`
    ${({ theme }) => css`
        position: sticky;
        top: 0;
        background: ${theme.colors.secondary.tints[1].hex};
        padding: 0.3rem 0;
        z-index: 998;
        border-bottom: ${theme.commonStyles.brandPixel} solid ${theme.colors.primaryContrast.hex};

        @media screen and (min-height: ${theme.breakpoints.small.value}px) {
            top: 3.5rem;

            @media ${theme.breakpoints.large.media} {
                top: 3.25rem;
            }
        }
    `}
`;

export const ToggleMenuContainer = styled.div<{ $isSticky: boolean }>`
    ${({ theme, $isSticky }) => css`
        display: flex;
        justify-content: flex-end;

        @media ${theme.breakpoints.medium.media} {
            display: block;

            ${$isSticky &&
            css`
                margin-left: 7.5rem;
            `}
        }

        @media ${theme.breakpoints.giant.media} {
            margin-left: 0;
        }
    `}
`;

export const ToggleMenuButton = styled.button`
    ${({ theme }) => css`
        padding: 0;
        border: none;
        background: transparent;
        color: ${theme.colors.primary.hex};
        font-size: 1.25rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        @media ${theme.breakpoints.large.media} {
            justify-content: flex-start;
            font-size: 1.5rem;
        }

        &:hover {
            ${Open} {
                transform: translateX(0.5rem) rotate(180deg);
            }
        }
    `}
`;

const commonIconStyles = css`
    ${({ theme }) => css`
        width: 1.5rem;
        height: 1.5rem;
        background: ${theme.colors.primary.hex};
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 50%;
        padding: 0.375rem;

        @media ${theme.breakpoints.large.media} {
            width: 2rem;
            height: 2rem;
            padding: 0.5rem;
        }
    `}
`;

export const Open = styled(Plus)`
    ${commonIconStyles}
    transition-duration: 0.25s;
`;

export const Overlay = styled.div<{ $isOpen: boolean }>`
    ${({ theme, $isOpen }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.3rem 0 2rem;
        background: ${theme.colors.secondary.tints[1].hex};
        max-height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: ${theme.commonStyles.brandPixel} solid ${theme.colors.primaryContrast.hex};

        ${!$isOpen &&
        css`
            display: none;
        `}
    `}
`;

export const Close = styled(Cross)`
    ${commonIconStyles}
    display: block;
    cursor: pointer;
`;

export const StyledIconButton = styled(IconButton)`
    ${({ theme }) => css`
        display: inline-flex;
        margin: 1rem 0;
        font-size: 1.125rem;
        padding: 0.25rem 0.5rem;

        && {
            border: ${theme.commonStyles.brandPixel} solid ${theme.colors.tertiary.hex};
        }

        ${Icon} {
            min-width: 2rem;
            width: 2rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 2rem 0;
            font-size: inherit;
            padding: 0.5rem 1rem;

            ${Icon} {
                min-width: 2.5rem;
                width: 2.5rem;
            }
        }
    `}
`;

export const StyledList = styled.ul`
    position: relative;
    padding: 0;
    margin: 0;
    animation: ${fadeSlideIn} 300ms ease-in forwards;
`;

export const StyledListItem = styled.li<{ $active: boolean; $open: boolean }>`
    ${({ theme, $active, $open }) => css`
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        line-height: 1.2;
        padding: 0.25rem 0.75rem 0.25rem 1.75rem;
        width: calc(100% + 1.5rem);
        left: -0.75rem;

        @media ${theme.breakpoints.large.media} {
            width: 100%;
            left: auto;
            font-size: 1.25rem;
            padding: 0.2rem 0.75rem 0.2rem 1.5rem;
            border-radius: 0.5rem;
            margin: 0.4rem 0;
        }

        ${$active &&
        css`
            background: ${theme.colors.secondary.hex};

            ${LinkIcon} {
                color: ${theme.colors.primary.hex};
            }
        `}

        ${$open &&
        css`
            @media ${theme.breakpoints.large.media} {
                background: ${theme.colors.secondary.tints[10].hex};
            }

            ${LinkIconWrapper} {
                left: auto;
                right: 0.5rem;
            }

            ${LinkIcon} {
                color: ${theme.colors.primary.hex};
            }
        `}

        &:hover {
            background: ${theme.colors.secondary.hex};

            ${LinkIcon} {
                color: ${theme.colors.primary.hex};
            }
        }
    `}
`;

export const LinkIconWrapper = styled.span`
    ${({ theme }) => css`
        position: absolute;
        left: 0.75rem;
        width: 1rem;

        @media ${theme.breakpoints.large.media} {
            left: 0.5rem;
        }
    `}
`;

export const LinkIcon = styled.svg<{ $isPage?: boolean }>`
    ${({ theme, $isPage }) => css`
        width: auto;
        height: 0.7rem;
        color: #7c7b7b;

        ${$isPage &&
        css`
            fill: transparent;
            stroke: ${theme.colors.primary.hex};
        `}
    `}
`;

export const NavItemLink = styled(Link)`
    text-decoration: none;
    line-height: 1.3;
`;

export const NavItemButton = styled.button`
    font-size: inherit;
    line-height: 1.3;
    border: none;
    background: transparent;
    padding: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
`;

export const BackButton = styled.button`
    ${({ theme }) => css`
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        color: ${theme.colors.primary.hex};
        display: block;
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        margin-bottom: 0.5rem;

        @media ${theme.breakpoints.large.media} {
            display: none;
        }
    `}
`;

export const BackButtonChevron = styled(Chevron)`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        background: linear-gradient(225deg, ${theme.colors.primary.hex} 0.9rem, transparent 0.9rem);
        transform: rotate(180deg);
        width: 0.65rem;
        padding: 0.175rem;
        height: auto;
    `}
`;
