import React from 'react';

import { Container, Inner, IconWrapper, StyledIcon, Message } from './AttentionBox.styled';

export interface Props {
    icon?: React.ElementType;
    text: string;
}

const AttentionBox: React.FC<Props> = props => {
    const { icon, text } = props;

    return (
        <Container>
            <Inner>
                {icon && (
                    <IconWrapper>
                        <StyledIcon as={icon} />
                    </IconWrapper>
                )}
                <Message>{text}</Message>
            </Inner>
        </Container>
    );
};

export default AttentionBox;
