import styled, { css } from 'styled-components';
import type CSS from 'csstype';

export const Container = styled.ul<{
    $columns: number;
    $listStyle?: CSS.Property.ListStyleType;
}>`
    ${({ theme, $columns, $listStyle }) => css`
        column-count: ${$columns};
        column-gap: 1.5rem;
        padding-inline-start: 1.5rem;
        color: var(--list-txt-colour, ${theme.colors.tertiary.hex})
            ${$listStyle &&
            css`
                list-style: ${$listStyle};
                padding-left: ${$listStyle === 'none' ? '0' : '1.5rem'};
            `};
    `}
`;

export const ListItem = styled.li`
    line-height: 1.4;
    padding-bottom: 0.5rem;
`;
