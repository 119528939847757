import React from 'react';

import type { Props as LinkProps } from 'Elements/Link/Link';
import { Mail, Link, Mobile } from '@solent-university/solent-icons';

import { Container } from '../SupportBlock.styled';
import { Heading, Details, Information, Contact, StyledLink, Icon } from './ContactInfo.styled';

export interface Props {
    heading?: string;
    headingType?: 'h2' | 'h3';
    icons?: boolean;
    contacts: {
        information?: string;
        phone?: LinkProps;
        email?: LinkProps;
        url?: LinkProps;
    }[];
    showBorder?: boolean;
}

const ContactInfo: React.FC<Props> = props => {
    const { heading, headingType = 'h2', contacts, showBorder = true, icons } = props;

    return (
        <Container $showBorder={showBorder}>
            {heading && (
                <Heading as={headingType} $contactInformation={!!contacts[0].information}>
                    {heading}
                </Heading>
            )}
            {contacts.map((contact, index) => (
                <Details key={index}>
                    {contact.information && <Information>{contact.information}</Information>}
                    {contact.email && (
                        <Contact>
                            <>
                                {icons ? <Icon as={Mail} /> : `Email:\u00A0`}
                                <StyledLink {...contact.email} />
                            </>
                        </Contact>
                    )}
                    {contact.phone && (
                        <Contact>
                            <>
                                {icons ? <Icon as={Mobile} /> : `Call:\u00A0`}
                                <StyledLink {...contact.phone} />
                            </>
                        </Contact>
                    )}
                    {contact.url && (
                        <Contact>
                            {icons && <Icon as={Link} />}
                            <StyledLink {...contact.url} openInNewTab />
                        </Contact>
                    )}
                </Details>
            ))}
        </Container>
    );
};

export default ContactInfo;
