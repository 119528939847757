import styled, { css } from 'styled-components';

import Icon from 'Elements/Icon';

export const Container = styled.div`
    ${({ theme }) => css`
        border-radius: 0.5rem;
        background: white;
        border: 0.2rem solid ${theme.colors.secondary.tints[0].hex};
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        border-radius: 0.5rem;
        margin: 0.1rem;
        padding: 1.25rem;
        background: ${theme.colors.secondary.tints[0].hex};
        text-align: center;
    `}
`;

export const IconWrapper = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.primary.hex};
        border-radius: 50%;
        height: 2.75rem;
        width: 2.75rem;
        padding: 0.25rem;
        display: grid;
        place-items: center;
        margin: 0 auto 0.5rem;

        @media ${theme.breakpoints.large.media} {
            height: 3.25rem;
            width: 3.25rem;
        }
    `}
`;

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        max-width: 75%;
        max-height: 75%;
    `}
`;

export const Message = styled.p`
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
    white-space: pre-wrap;
`;
