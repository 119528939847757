import styled, { css } from 'styled-components';

export const DescriptionWrapper = styled.div`
    ${({ theme }) => css`
        @media ${theme.breakpoints.large.media} {
            p:first-of-type {
                margin-top: 0;
            }

            h2:first-of-type,
            h3:first-of-type {
                margin-top: -0.75rem;
                margin-bottom: 0.75rem;
            }
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        max-width: 25rem;

        @media ${theme.breakpoints.large.media} {
            max-width: 100%;
        }
    `}
`;
