import React from 'react';
import type CSS from 'csstype';

import { Container, ListItem } from './List.styled';

export interface Props {
    items: {
        text: string;
        id: string;
    }[];
    columns?: number; // configurable columns
    listStyle?: CSS.Property.ListStyle;
    color?: string;
}

const List: React.FC<Props> = props => {
    const { items, columns = 1, listStyle, color } = props;

    return (
        <Container
            $columns={columns}
            $listStyle={listStyle}
            style={
                {
                    '--list-txt-colour': color
                } as React.CSSProperties
            }
        >
            {items.map(item => (
                <ListItem key={item.id}>{item.text}</ListItem>
            ))}
        </Container>
    );
};

export default List;
